import React, { Fragment, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Coordinate, Item, Data, GroupItem, Selectable, SingleGroupItem } from './types';
import cn from 'classnames';
import './app.scss';
import { RemanChoices, RemanResults } from 'ui/src/types';
import { addRemanAndDepositItemsToCart, ButtonResult, ButtonType, getAvailabilityDisplay, Mode, partition, popup, PopupSize, QuantitySelector, RemanufacturedChoice, showNotAuthorizedPopup, updateCartItem, updateMultipleCartItems } from 'ui';
import { Inventories } from 'ui/src/components/Inventories/Inventories';

const translationState = window.app.preloadState.translation;

const lerp = (c1: Coordinate, c2: Coordinate, t: number) => ({ x: c1.x * (1 - t) + c2.x * t, y: c1.y * (1 - t) + c2.y * t });

export function ExplodedView() {
    const { viewProperties, zoom, setZoom, beginGrab, updateSize, transformPoint, zoomTo, setPosition, position: viewPosition } = usePanZoomImage();
    const [selectedPartAndIndex, setSelectedPartAndIndex] = useState<readonly [string, number] | null>(null);
    const [isPopup, setPopup] = useState(false);

    const params = new URLSearchParams(location.search);
    const returnToProduct = params.get("articleNumber") ? (() => {
        const url = document.referrer;
        const char = url.includes('?') ? '&' : '?';
        location.assign(url.includes('explodedView') ? url : `${url}${char}explodedView=true`);
    })
    : null;

    useEffect(() => {
        const articleNumberString = params.get("articleNumber");
        if (articleNumberString) {
            const part = groupedParts.find(part => part.items.some(item => item.code === articleNumberString));
            if (part) {
                setTimeout(() => {
                    toggleSelectedPartAndIndex(part.position, 0);
                }, 1000);
            }
        }
    }, []);

    const [data, setData] = useState<Data>({
        displayName: "",
        imageUrl: "",
        invalidItems: [],
        invalidItemTitle: "",
        items: [],
        noItemsMessage: "",
        ...(window.app.preloadState.explodedView ?? {})
    });
    
    const parts = data?.items ?? [];
    const unduplicatedGroupedParts = groupBy<Item & Selectable, string>(parts, p => p.position)
        .map(g => ({ key: g.key, items: dedup(g.items, i => i.code) }));
    const groupedParts: GroupItem[] = unduplicatedGroupedParts
        .map(g => ({
            position: g.key,
            items: g.items.map(i => ({ ...i, coordinate: undefined })),
            coordinates: dedup(g.items.map(i => i.coordinate), c => c.x * 2048 + c.y)
        }));

    const onDropdownSelected = (groupPosition: string, itemCode: string | null) => {
        const group = unduplicatedGroupedParts.find(g => g.key === groupPosition)!;
        group.items.forEach(i => i.selected = false);
        if (!!itemCode) {
            group.items.find(i => i.code === itemCode)!.selected = true;
        }
        setData({...data});
    };

    const toggleSelectedPartAndIndex = (position: string, index: number, coordinates?: Coordinate) => {

        setSelectedPartAndIndex(
            selectedPartAndIndex?.[0] === position && (selectedPartAndIndex[1] === index || index === -1)
                ? null
                : [position, index] as const
        )
        if (coordinates) {
            let totalTime = 0;
            let lastTimeStamp = performance.now();
            const smoother = (t: DOMHighResTimeStamp) => {
                totalTime += t - lastTimeStamp;
                lastTimeStamp = t;
                if (totalTime < 500) {
                    setPosition(p => lerp(p, coordinates, totalTime/500));
                    requestAnimationFrame(smoother);
                } else {
                    setPosition(coordinates);
                }
            };
            smoother(lastTimeStamp);
        }
        scrollToTicket(position);
    }

    const scrollToTicket = (position: string) => {
        const ticket = document.querySelector<HTMLImageElement>('#id_' + position);
        if (!ticket) return;
        ticket.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
        });
    }

    const imageElement = document.querySelector<SVGImageElement>('.explodedView>.evBody>.evImage svg image')!;
    const bbox = imageElement?.getBBox();

    const changePopup = (value: boolean) => {
        if (!value) {
            setZoom(1);
        } else {
            if (document.body.clientWidth < 1024) {
                if (!selectedPartAndIndex) {
                    const item = groupedParts[0];
                    if (item) {
                        toggleSelectedPartAndIndex(item.position, 0, item.coordinates[0]);
                    }
                }
            }
        }
        setTimeout(() => {
            if (value) {
                document.documentElement.classList.add("overflow-hidden");
            } else {
                document.documentElement.classList.remove("overflow-hidden");
            }
        });
        setPopup(value);
        setTimeout(() => {
            const svgElement = document.querySelector<SVGSVGElement>('.explodedView>.evBody>.evImage svg')!;
            const imageElement = document.querySelector<SVGImageElement>('.explodedView>.evBody>.evImage svg image')!;
            const bbox = imageElement.getBBox();
            updateSize({
                clientWidth: svgElement.clientWidth,
                clientHeight: svgElement.clientHeight,
                naturalWidth: bbox.width,
                naturalHeight: bbox.height
            });
        });
    }

    useEffect(() => {
        const onWheel = function (ev: WheelEvent) {
            if ((ev.target as any).tagName === "A" && (ev.target as any).parentNode.classList.contains('evImage')) {
                ev.preventDefault();
                return;
            }
            const imageElement = document.querySelector<SVGImageElement>('.explodedView>.evBody>.evImage svg image');
            if (imageElement === ev.target || imageElement?.parentElement === ev.target) {
                zoomTo(ev.offsetX, ev.offsetY, -Math.sign(ev.deltaY));
                ev.preventDefault();
            }
        };
        document.addEventListener('wheel', onWheel, { passive: false });
        return () => document.removeEventListener('wheel', onWheel, { passive: false } as any);
    }, [zoomTo]);

    useEffect(() => {
        const svgElement = document.querySelector<SVGSVGElement>('.explodedView>.evBody>.evImage svg')!;
        svgElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
        })
    }, []);

    const scaleThroughMagicNumber = (coordinate: number, naturalMultiplier: number) => {
        return (coordinate / 2048) * naturalMultiplier;
    }

    const partsInSet = groupedParts
        .map(group => group.items.length == 1 ? group.items[0] : group.items.find(item => item.selected));

    const partsToBuy = partsInSet
        .filter((item): item is Item => item != null)
        .filter(item => (!!item.unitPrice || item.mode === Mode.AddToCart) && item.bundleQuantity > 0 && item.mode !== Mode.ContactSupport && item.mode !== Mode.RequestQuotationByEmail);

    const buyAll = async () => {
        var codesWithQuantity = partsToBuy.map(item => ({ code: item.code, quantity: item.bundleQuantity }));

        const response = await fetch('/api/pdp/reman-item-details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Swecon-Current-Language': window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(codesWithQuantity.map(item => item.code)),
        });

        if (!response.ok) {
            console.error(await response.text());
            return;
        }
        const withRemanItems: RemanChoices = await response.json();
        if (Object.keys(withRemanItems).length === 0) {
            await updateMultipleCartItems(codesWithQuantity);
            location.reload();
            return;
        }
        let result: RemanResults = {};

        const changeResult = (results: RemanResults) => {
            result = results;
        }

        const quantityOverride = Object.fromEntries(partsToBuy.map(item => ([
            item.code,
            (item.remanQuantity || item.quantity) + item.bundleQuantity
        ])));

        const popUpResult = await popup(
            translationState["remanufacturedItemPopUp.selectItemToAddToCart"],
            <RemanufacturedChoice changeResult={changeResult} sparePartIds={Object.keys(withRemanItems)} quantityOverride={quantityOverride} />,
            [
                { label: translationState["remanufacturedItemPopUp.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translationState["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.ExtraLarge, 
            "noPaddingsPopUp"
        );

        if (popUpResult !== ButtonResult.Ok) {
            return;
        }

        const [toSendNormal, toSendAdditive] = partition(codesWithQuantity, item => item.code in withRemanItems);

        await updateMultipleCartItems(toSendAdditive);
        for (const item of toSendNormal) {
            const data = result[item.code];
            if (!data.selectedReman) {
                await updateCartItem(item.code, data.sparePartQuantity);
            } else {
                await addRemanAndDepositItemsToCart(item.code, data.remanQuantity);
            }
        }
        location.reload();
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const target = document.querySelector("#explodedViewImageElement") as SVGImageElement;
            const bbox = target.getBBox();
            const parent = target.parentNode as SVGSVGElement;
            if (bbox.width > 0 && bbox.height > 0
                && (bbox.width != 300 && bbox.height != 150) // Internet browser bug regarding default canvas size
            ) {
                clearInterval(intervalId);
                updateSize({
                    clientWidth: parent.clientWidth,
                    clientHeight: parent.clientHeight,
                    naturalWidth: bbox.width,
                    naturalHeight: bbox.height
                });
            }
        }, 100);
    }, []);

    const isBuyButtonEnabled = (partsToBuy.length == partsInSet.length) && data.invalidItems.length == 0;

    const showBuyAllInfoPopUp = () => {
        popup(
            translationState["explodedViewTranslations.explodedViewPopUpTitle"],
            translationState["explodedViewTranslations.explodedViewPopUpDescription"],
            [
                { label: translationState["explodedViewTranslations.okButton"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ]
        );
    }

    return (
        <div className="page">
            <div className="pageHeader m-bottom--x2">
                <h1>{data.displayName}</h1>
            </div>
            {returnToProduct && <div className="m-bottom--x2">
                <button className="btn btn--outlined firstLetterCapital returnToProductButton" onClick={returnToProduct}>{translationState["explodedViewTranslations.returnToProduct"]}</button>
            </div>}
            <div className={"explodedView" + (isPopup ? " evPopup" : "")}>
                <div className="evHeader">
                    <div className="rangeInput">
                        <span className="rangeInputBefore" onClick={() => setZoom(zoom - 1)}></span>
                        <input type="range" value={zoom} min={1} max={15} onInput={ev => setZoom((ev.target as HTMLInputElement).valueAsNumber)} />
                        <span className="rangeInputAfter" onClick={() => setZoom(zoom + 1)}></span>
                    </div>
                    {
                        isPopup
                            ? (
                                <div className="openPopupIconLabel">
                                    <a onClick={() => changePopup(false)} className="evClosePopup"></a>
                                </div>
                            )
                            : (<>
                                <div className="openPopupIconLabel">
                                    <a onClick={() => changePopup(true)} className="evOpenPopup"></a>
                                    <span className="articleCount">{groupedParts.length} {translationState["sparePartItem.articles"]}</span>
                                </div>
                                {
                                    (parts.length > 0 && parts[0].mode === Mode.NotAuthorized) ? (
                                        <span className="btn btn--outlined" title={translationState["sparePartVariationButtonOptions.insufficientRightsToBuy"]}
                                            onClick={showNotAuthorizedPopup}>{translationState["sparePartVariationButtonOptions.moreInformation"]}</span>
                                    )
                                        : (
                                            <>
                                                <button id="ExplodedViewBuyAllButton" disabled={!isBuyButtonEnabled} className="btn btn--primary firstLetterCapital" onClick={buyAll}>{translationState["sparePartItem.buyAll"]}</button>
                                                {!isBuyButtonEnabled && <span className="buyAllInfoButton" onClick={showBuyAllInfoPopUp}></span>}
                                            </>
                                        )
                                }
                            </>)
                    }
                </div>
                <div className="evBody">
                    <div className="evImage">
                        <svg
                            viewBox={viewProperties}
                            onPointerDown={ev => beginGrab(ev)}
                            xmlns="http://www.w3.org/2000/svg">
                            <image id="explodedViewImageElement"
                                href={data.imageUrl}
                            ></image>
                        </svg>
                        {
                            !!imageElement && [...groupedParts]
                                .map(part => ({ position: part.position, coordinates: part.coordinates, firstPart: part.items[0] }))
                                .flatMap(part => part.coordinates.map((c, i) => ({ position: part.position, firstPart: part.firstPart, coordinates: c, index: i })))
                                .map(part => {
                                    const coordinates = part.coordinates;
                                    const x = scaleThroughMagicNumber(coordinates.x, bbox.width);
                                    const y = scaleThroughMagicNumber(coordinates.y, bbox.height);
                                    const point = transformPoint(x, y);
                                    return <a key={`${part.position} ${part.index} ${coordinates.x} ${coordinates.y}`}
                                        className={selectedPartAndIndex && selectedPartAndIndex[0] == part.position && (selectedPartAndIndex[1] == part.index || selectedPartAndIndex[1] === -1) ? "activePoint" : ""}
                                        style={{ left: point.x, top: point.y }}
                                        onClick={() => toggleSelectedPartAndIndex(part.position, part.index, isPopup ? { x, y } : undefined)}
                                    >{part.position}</a>
                                })
                        }
                        {
                            !!imageElement && dedup(data.invalidItems, item => `${item.position} ${item.xCoordinate} ${item.yCoordinate}`)
                                .map(part => ({ position: part.position, coordinates: { x: part.xCoordinate, y: part.yCoordinate } }))
                                .map(part => {
                                    const coordinates = part.coordinates;
                                    const x = scaleThroughMagicNumber(coordinates.x, bbox.width);
                                    const y = scaleThroughMagicNumber(coordinates.y, bbox.height);
                                    const point = transformPoint(x, y);
                                    return <a key={`${part.position} ${coordinates.x} ${coordinates.y}`}
                                        className="invalidItemPointer"
                                        onClick={() => toggleSelectedPartAndIndex(part.position, -2, isPopup ? { x, y } : undefined)}
                                        style={{ left: point.x, top: point.y }}
                                        title={data.invalidItemTitle}
                                    >{part.position}</a>
                                })
                        }
                        <div className="mobileWhiteBackground">
                            {
                                !!imageElement && !!selectedPartAndIndex && selectedPartAndIndex[1] !== -1 &&
                                [...groupedParts
                                    .filter(part => isPopup && selectedPartAndIndex[0] == part.position)
                                    .map(part => {
                                        const coordinates = part.coordinates[selectedPartAndIndex[1]];
                                        const point = transformPoint(scaleThroughMagicNumber(coordinates.x, bbox.width), scaleThroughMagicNumber(coordinates.y, bbox.height));
                                        return (<div key={`${part.position} ${coordinates.x} ${coordinates.y}`} className="floatingActivePoint" style={{ left: point.x, top: point.y }}>
                                            <ItemPart identifier={"id_" + part.position} popupSelection={true} onSelect={() => { }} isSelected={true} parts={part.items} onDropdownSelect={onDropdownSelected.bind(null, part.position)} />
                                        </div>)
                                    }),
                                (<div key="invalidItem" className="floatingActivePoint invalidItem d-none d-md-block">
                                    {data.invalidItemTitle}
                                </div>)
                                ]
                                    .filter((_, i) => i === 0)
                            }
                        </div>
                    </div>
                    <div className="evParts">
                        {
                            groupedParts.length > 0 ?
                                [...groupedParts]
                                    .map(part => {
                                        return <Fragment key={part.position}>
                                            <ItemPart identifier={"id_" + part.position} popupSelection={false} onSelect={(code) => toggleSelectedPartAndIndex(code, part.coordinates.length === 1 ? 0 : -1)} parts={part.items} isSelected={selectedPartAndIndex?.[0] == part.position} onDropdownSelect={onDropdownSelected.bind(null, part.position)} />
                                            <hr />
                                        </Fragment>
                                    })
                                :
                                <div className="emptyListMessage">{data.noItemsMessage}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

type Size = { clientWidth: number, clientHeight: number, naturalWidth: number, naturalHeight: number };

const usePanZoomImage = () => {
    const [size, updateSize] = useState({ clientWidth: 1, clientHeight: 1, naturalWidth: 1, naturalHeight: 1 });

    useLayoutEffect(() => {
        const callback = () => {
            const svgElement = document.querySelector<SVGSVGElement>('.explodedView>.evBody>.evImage svg')!;
            const imageElement = document.querySelector<SVGImageElement>('.explodedView>.evBody>.evImage svg image')!;
            const bbox = imageElement.getBBox();
            updateSize(() => ({
                clientWidth: svgElement.clientWidth,
                clientHeight: svgElement.clientHeight,
                naturalWidth: bbox.width,
                naturalHeight: bbox.height
            }));
        };
        window.addEventListener('resize', callback);
        return () => window.removeEventListener('resize', callback);
    }, []);

    const width = size.naturalWidth;
    const height = size.naturalHeight;
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const [zoom, setZoom] = useState(1);
    const [position, setPosition] = useState({ x: halfWidth, y: halfHeight });

    const zoomModifier = Math.pow(1.1, zoom - 1);

    const left = position.x - halfWidth / zoomModifier;
    const right = position.x + halfWidth / zoomModifier;
    const top = position.y - halfHeight / zoomModifier;
    const bottom = position.y + halfHeight / zoomModifier;

    const beginGrab = useCallback((ev: React.MouseEvent<SVGSVGElement>) => {
        const isPrimaryButton = (ev.buttons & 1) == 1;
        if (!isPrimaryButton) return;

        const controller = new AbortController();
        const signal = controller.signal;

        const mouseMove = (mouseMoveEvent: MouseEvent) => {
            mouseMoveEvent.stopPropagation();
            mouseMoveEvent.preventDefault();
            const moveX = mouseMoveEvent.movementX;
            const moveY = mouseMoveEvent.movementY;
            const widthModifier = size.naturalWidth / size.clientWidth;
            const heightModifier = size.naturalHeight / size.clientHeight;
            setPosition(p => ({
                x: p.x - moveX * widthModifier / zoomModifier,
                y: p.y - moveY * heightModifier / zoomModifier
            }));
        };

        document.addEventListener('pointermove', mouseMove, { signal });
        document.addEventListener('pointerup', () => controller.abort(), { once: true, signal });
    }, [size, zoom]);

    const reverseTransformPoint = (x: number, y: number) => {
        const wRatio = size.clientWidth / size.naturalWidth;
        const hRatio = size.clientHeight / size.naturalHeight;

        return {
            x: ((x - size.clientWidth / 2) / zoomModifier + size.clientWidth / 2) / wRatio - (halfWidth - position.x),
            y: ((y - size.clientHeight / 2) / zoomModifier + size.clientHeight / 2) / hRatio - (halfHeight - position.y),
        }
    }

    return {
        updateSize: (size: Size) => {
            updateSize(size);
            setPosition({ x: size.naturalWidth / 2, y: size.naturalHeight / 2 });
        },
        viewProperties: `${left ?? 0} ${top ?? 0} ${(right ?? 0) - (left ?? 0)} ${(bottom ?? 0) - (top ?? 0)}`,
        zoom,
        setZoom: (zoom: number) => {
            if (zoom <= 15 && zoom >= 1) {
                setZoom(zoom)
            }
        },
        position,
        setPosition,
        zoomTo: (x: number, y: number, direction: number) => {
            const inImagePoint = reverseTransformPoint(x, y);
            const vector = { x: inImagePoint.x - position.x, y: inImagePoint.y - position.y };
            vector.x *= direction;
            vector.y *= direction;
            const newPosition = { x: position.x + vector.x / 10, y: position.y + vector.y / 10 };
            if (zoom + direction <= 15 && zoom + direction >= 1) {
                setZoom(zoom + direction);
                setPosition(newPosition);
            }
        },
        beginGrab,
        transformPoint: (x: number, y: number) => {
            const wRatio = size.clientWidth / size.naturalWidth;
            const hRatio = size.clientHeight / size.naturalHeight;
            const ratio = Math.min(wRatio, hRatio);
            const dX = Math.abs(size.clientWidth - size.naturalWidth * ratio);
            const dY = Math.abs(size.clientHeight - size.naturalHeight * ratio);
            return {
                x: ((x + (halfWidth - position.x)) * ratio - size.clientWidth / 2 + dX / 2) * zoomModifier + size.clientWidth / 2,
                y: ((y + (halfHeight - position.y)) * ratio - size.clientHeight / 2 + dY / 2) * zoomModifier + size.clientHeight / 2
            }
        }
    }
}






const showInventoryPopup = (part: SingleGroupItem) => {
    popup(
        translationState["inventoryPopup.stockStatusAtFacility"],
        <Inventories itemCode={part.code} itemDisplayName={part.displayName} />,
        [
            { label: translationState["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
        ],
        PopupSize.Large
    );
}

type ItemPartProps = {
    identifier: string;
    isSelected: boolean;
    popupSelection: boolean;
    onSelect: (code: string) => void;
    parts: SingleGroupItem[];
    onDropdownSelect: (code: string | null) => void;
}

export const ItemPart = ({ identifier, isSelected, onSelect, parts, popupSelection, onDropdownSelect }: ItemPartProps) => {

    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const articleNumberString = params.get("articleNumber");
        if (articleNumberString) {
            const partIndex = parts.findIndex(part => part.code === articleNumberString);
            if (partIndex > -1) {
                setPartIndex(partIndex + 1);
            }
        }
    }, []);

    const [partIndex, setPartIndex] = useState<number>(parts.length === 1 ? 1 : 0);
    const selectPart = (index: number) => {
        setPartIndex(index);
        parts.forEach(p => p.selected = false);
        if (index > 0) {
            parts[index - 1].selected = true;
            onDropdownSelect(parts[index - 1].code);
        } else {
            onDropdownSelect(null);
        }
    }
    const noPrice = !parts[partIndex - 1]?.unitPrice;

    const showRemanufacturePopUp = async (itemCode: string) => {

        let result: RemanResults[string] = undefined!;

        const changeResult = (results: RemanResults) => {
            result = results[itemCode];
        }

        const remanQuantity = parts[partIndex - 1].remanQuantity;

        const override = remanQuantity
            ? {
                [itemCode]: remanQuantity
            } : undefined;

        const popupResult = await popup(
            translationState["remanufacturedItemPopUp.selectItemToAddToCart"],
            <RemanufacturedChoice changeResult={changeResult} sparePartIds={[itemCode]} quantityOverride={override} />,
            [
                { label: translationState["remanufacturedItemPopUp.remove"], result: ButtonResult.No, type: ButtonType.Link, className: "deleteButton", visible: override != null },
                { label: translationState["remanufacturedItemPopUp.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translationState["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.ExtraLarge,
            "noPaddingsPopUp"
        );
        if (popupResult == ButtonResult.Ok) {
            return result;
        } else if (popupResult == ButtonResult.No) {
            parts[partIndex - 1].remanQuantity = 0;
            return {
                selectedReman: false,
                sparePartQuantity: 0,
                remanQuantity: 0
            };
        } else {
            return undefined;
        }
    }

    const onAddReman = async (code: string, quantity: number) => {
        await addRemanAndDepositItemsToCart(code, quantity);
        parts[partIndex - 1].remanQuantity = quantity;
    }

    return (<div className={"evPartInternal"} onClick={() => onSelect(parts[0].position)}>
        {parts.length > 1 && partIndex !== 0 && <select className="variantSelect" value={partIndex} onChange={ev => selectPart(+ev.target.value)}>
            <option value={0}>{translationState["explodedViewTranslations.selectVariant"]}</option>
            {parts.map((part, i) => <option key={part.code} value={i + 1}>{part.displayName} | #{part.code}</option>)}
        </select>}
        <div id={identifier} className={cn("evItemPartTicket itemPart", popupSelection && "d-md-none", isSelected && "selected")}>
            <h4>{parts[0].position}</h4>
            {partIndex > 0 && <>
                <FirstPart part={parts[partIndex - 1]} noPrice={noPrice} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />
                <ItemRows part={parts[partIndex - 1]} noPrice={noPrice} mobile={false} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />
            </>}
            {partIndex === 0 && <select className="variantSelect" value={partIndex} onChange={ev => selectPart(+ev.target.value)}>
                <option value={0}>{translationState["explodedViewTranslations.selectVariant"]}</option>
                {parts.map((part, i) => <option key={part.code} value={i + 1}>{part.displayName} | #{part.code}</option>)}
            </select>
            }
        </div>
        {popupSelection && <div className="evItemPartMobileTicket d-none d-md-flex">
            <div className="evItemPartTicket itemPart selected">
                <h4>{parts[0].position}</h4>
                {partIndex > 0 && <FirstPart part={parts[partIndex - 1]} noPrice={noPrice} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />}
                {partIndex === 0 && <select className="variantSelect" value={partIndex} onChange={ev => selectPart(+ev.target.value)}>
                    <option value={0}>{translationState["explodedViewTranslations.selectVariant"]}</option>
                    {parts.map((part, i) => <option key={part.code} value={i + 1}>{part.displayName} | #{part.code}</option>)}
                </select>}
            </div>
            {partIndex > 0 && <ItemRows part={parts[partIndex - 1]} noPrice={noPrice} mobile={true} onAddReman={onAddReman} showRemanufacturePopUp={showRemanufacturePopUp} />}
        </div>}
    </div>)
}

type FirstPartProps = {
    part: SingleGroupItem;
    noPrice: boolean;
    onAddReman: (code: string, quantity: number) => Promise<void>;
    showRemanufacturePopUp: (itemCode: string) => Promise<RemanResults[string] | undefined>;
}

const FirstPart = ({ part, noPrice, onAddReman, showRemanufacturePopUp }: FirstPartProps) => {

    const [availabilityDisplay, setAvailabilityDisplay] = useState(part.inventory?.warehouse?.availabilityDisplay);
    const [hasStockAvailable, setHasStockAvailable] = useState(part.inventory?.branch?.hasEnoughStock ?? false);

    const onItemUpdate = async (code: string, quantity: number) => {
        await updateCartItem(code, quantity);
        const stockAvailability = await getAvailabilityDisplay(code);
        if (stockAvailability.availability) {
            setAvailabilityDisplay(stockAvailability.availability);
        }
        setHasStockAvailable(stockAvailability.hasEnoughInStock);
    };

    return <>
        <div className="description">
            <div className="information">
                <h4><a href={part.url}>{part.displayName}</a></h4>
                <a href={part.url} className="itemNumber firstLetterCapital">{translationState["sparePartItem.articleNo"]}: {part.code}</a>
                {part.labels?.length > 0 &&
                    <p className="labels">
                        {part.labels.map(label =>
                            <span key={label.text} className="label">{label.text}</span>
                        )}
                    </p>
                }
                <div className="delivery">
                    <div>
                        <p className="firstLetterCapital">{translationState["sparePartItem.quantityFrp"]}: {part.inventory?.packageQuantity ?? 1}</p>
                        {!!part.itemUnitId && <p className="firstLetterCapital">{translationState["sparePartItem.unit"]}: {part.itemUnitId}</p>}
                    </div>
                    <div className="d-lg-none">
                        {part.mode !== Mode.AddToCart ? <>
                            <span
                                className={cn("truckIcon", {
                                    "redText": !part.inventory?.warehouse?.isAvailable ?? "false",
                                    "greenText": part.inventory?.warehouse?.isAvailable ?? "false"
                                })}
                            >
                                {(part.inventory?.warehouse?.isAvailable ?? "false") ? <span dangerouslySetInnerHTML={{ __html: availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translationState["inventoryPopup.notInStock"]}</span>}
                            </span>
                            {part.inventory?.branch?.branchName ?
                                <span
                                    onClick={() => showInventoryPopup(part)}
                                    className={cn("", {
                                        "greenText": hasStockAvailable,
                                        "redText": !hasStockAvailable,
                                    })}
                                >
                                    {part.inventory?.branch?.branchName}
                                </span>
                                :
                                <span
                                    onClick={() => showInventoryPopup(part)}
                                >
                                    {translationState["inventories.selectBranch"]}
                                </span>
                            }
                        </> : <span className={"truckIcon greenText"}>
                            <span className={"d-inline firstLetterCapital"}>{translationState["common.deliveryTimeToBeChecked"]}</span>
                        </span>}
                    </div>
                </div>
            </div>
        </div>
        <div className={cn('cart d-lg-none', noPrice && "noBorder center")}>
            {
                part.mode !== Mode.RequestQuotationByEmail && part.mode !== Mode.ContactSupport && <div className="prices">
                    <p className="newPrice">{part.discountedPrice ?? part.unitPrice}</p>
                    {!!part.discountedPrice && <>
                        <p className="oldPrice">{part.unitPrice}</p>
                        <p className="discount firstLetterCapital">{translationState["sparePartItem.discount"]}: {part.discountPercentageDisplay}</p>
                    </>}
                </div>
            }
            <QuantitySelector
                hasRemanInCart={!!part.remanQuantity}
                forceMinValue={false}
                step={part.inventory?.quantityStep ?? 1}
                min={part.inventory?.minimumQuantity ?? 0}
                max={part.inventory?.maximumQuantity ?? 0}
                initialValue={part.quantity}
                mode={part.mode}
                requestQuotationByEmail={part.requestQuotationByEmail}
                contactSupportLink={part.contactSupportLink}
                onRemove={(f: () => void) => onItemUpdate(part.code, 0).then(() => f())}
                onChange={(quantity: number) => onItemUpdate(part.code, quantity)}
                onAddReman={onAddReman.bind(null, part.code)}
                showRemanufacturePopUp={part.hasRemanItem ? showRemanufacturePopUp.bind(undefined, part.code) : undefined}
                hasRemanAvailable={part.hasRemanItem}
            />
        </div>
    </>;
}

type ItemRowsProps = {
    part: SingleGroupItem;
    noPrice: boolean;
    mobile: boolean;
    onAddReman: (code: string, quantity: number) => Promise<void>;
    showRemanufacturePopUp: (itemCode: string) => Promise<RemanResults[string] | undefined>;
}

const ItemRows = ({ part, noPrice, mobile, onAddReman, showRemanufacturePopUp }: ItemRowsProps) => {

    const [availabilityDisplay, setAvailabilityDisplay] = useState(part.inventory?.warehouse?.availabilityDisplay);
    const [hasStockAvailable, setHasStockAvailable] = useState(part.inventory?.branch?.isAvailable ?? false);

    const onItemUpdate = async (code: string, quantity: number) => {
        await updateCartItem(code, quantity);
        const stockAvailability = await getAvailabilityDisplay(code);
        if (stockAvailability.availability) {
            setAvailabilityDisplay(stockAvailability.availability);
        }
        setHasStockAvailable(stockAvailability.hasEnoughInStock);
    };

    return <div className={cn("itemRows", !mobile && "d-nlg-none")}>
        <div className="deliveryStatus">
            <div className="firstLetterCapital">{translationState["sparePartItem.stockStatus"]}:</div>
            <div>
                {part.mode !== Mode.AddToCart ? <>
                    <span
                        className={cn("truckIcon", {
                            "redText": !part.inventory?.warehouse?.isAvailable ?? "false",
                            "greenText": part.inventory?.warehouse?.isAvailable ?? "false"
                        })}
                    >
                        {(part.inventory?.warehouse?.isAvailable ?? "false") ? <span dangerouslySetInnerHTML={{ __html: availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translationState["inventoryPopup.notInStock"]}</span>}
                    </span>
                    {part.inventory?.branch?.branchName ?
                        <span
                            onClick={() => showInventoryPopup(part)}
                            className={cn("", {
                                "greenText": hasStockAvailable,
                                "redText": !hasStockAvailable,
                            })}
                        >
                            {part.inventory?.branch?.branchName}
                        </span>
                        :
                        <span
                            onClick={() => showInventoryPopup(part)}
                        >
                            {translationState["inventories.selectBranch"]}
                        </span>
                    }
                </> : <span className={"truckIcon greenText"}>
                    <span className={"d-inline-block firstLetterCapital"}>{translationState["common.deliveryTimeToBeChecked"]}</span>
                </span>}
            </div>
        </div>
        {!noPrice &&
            <div>
                <div className="firstLetterCapital">{translationState["sparePartItem.price"]} :</div>
                <div className="evBold">{part.discountedPrice ?? part.unitPrice}</div>
            </div>
        }
        {!!part.discountedPrice &&
            <div>
                <div className="firstLetterCapital">{translationState["sparePartItem.discount"]} :</div>
                <div>
                    <span className="evCross">{part.unitPrice}</span>
                    <span className="evBold">{part.discountPercentageDisplay}</span>
                </div>
            </div>
        }
        <div>
            <QuantitySelector
                hasRemanInCart={!!part.remanQuantity}
                className="w-100"
                forceMinValue={false}
                step={part.inventory?.quantityStep ?? 1}
                min={part.inventory?.minimumQuantity ?? 0}
                max={part.inventory?.maximumQuantity ?? 0}
                initialValue={part.quantity}
                mode={part.mode}
                requestQuotationByEmail={part.requestQuotationByEmail}
                contactSupportLink={part.contactSupportLink}
                onRemove={(f: () => void) => onItemUpdate(part.code, 0).then(() => f())}
                onChange={(quantity: number) => onItemUpdate(part.code, quantity)}
                onAddReman={onAddReman.bind(null, part.code)}
                showRemanufacturePopUp={part.hasRemanItem ? showRemanufacturePopUp.bind(undefined, part.code) : undefined}
            />
        </div>
    </div>;
}


export const groupBy = <T, V>(data: T[], getter: (v: T) => V): { key: V, items: T[] }[] => {
    const groupMap = new Map<V, T[]>();
    data.forEach(d => {
        const value = getter(d);
        if (!groupMap.has(value)) {
            groupMap.set(value, []);
        }
        groupMap.get(value)!.push(d);
    });
    return Array.from(groupMap).map(g => ({ key: g[0], items: g[1] }));
}

export const dedup = <T, V>(data: T[], getter: (v: T) => V): T[] => {
    const dedupMap = new Map<V, T>();
    data.forEach(d => dedupMap.set(getter(d), d));
    return Array.from(dedupMap).map(p => p[1]);
};

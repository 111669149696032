// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JnMthDcw4bHz1A8zHmMa{display:flex;flex-direction:row;flex-wrap:wrap;gap:16px;margin-bottom:16px}.ATiGgaXhCShzK8ycx4w2{height:1500px}.Qm1QCGX0tOigiHlEyxpM{display:flex;gap:61px;flex-direction:row}.Qm1QCGX0tOigiHlEyxpM .JtWO6uqu_oJ42pPheqFi{flex:0 0 226px;display:flex;flex-direction:column;gap:32px;position:sticky;top:18px;align-self:start}.Qm1QCGX0tOigiHlEyxpM .JtWO6uqu_oJ42pPheqFi h3{cursor:pointer;display:flex;justify-content:space-between}.Qm1QCGX0tOigiHlEyxpM .JtWO6uqu_oJ42pPheqFi hr{margin-bottom:8px}.Qm1QCGX0tOigiHlEyxpM .yHjWhphg9fjX1EjaJK0m{display:none}.Qm1QCGX0tOigiHlEyxpM .mOy8O4XR5z0EnXt5vb6B{flex:1 1 auto}@media(max-width: 1024.02px){.Qm1QCGX0tOigiHlEyxpM{flex-direction:column}.Qm1QCGX0tOigiHlEyxpM .JtWO6uqu_oJ42pPheqFi{display:none}.Qm1QCGX0tOigiHlEyxpM .yHjWhphg9fjX1EjaJK0m{align-self:end;margin-top:-64px;display:block;text-align:right;position:relative;width:100%}}@media(max-width: 768.02px){.ATiGgaXhCShzK8ycx4w2{height:3126px}}`, ""]);
// Exports
export var sparePartItems = `JnMthDcw4bHz1A8zHmMa`;
export var itemsPlaceholder = `ATiGgaXhCShzK8ycx4w2`;
export var sparePartItemsModule = `Qm1QCGX0tOigiHlEyxpM`;
export var sparePartsFacets = `JtWO6uqu_oJ42pPheqFi`;
export var sparePartsFacetsMobile = `yHjWhphg9fjX1EjaJK0m`;
export var sparePartsList = `mOy8O4XR5z0EnXt5vb6B`;
export default ___CSS_LOADER_EXPORT___;
